import { useEffect } from 'react';

import { useAppContext } from '@contexts/AppContext';
import { WindowLocation } from '@reach/router';
import StoreService from '@services/StoreService';
import { AbsPage } from '@src/modules/AnnualBoilerService/Abs';
import PromoService from '@services/PromoService';
import useQuote from '@hooks/useQuote';
import { CoverType } from '@entities/enums';

interface Props {
  location: WindowLocation;
}

const Abs = (props: Props) => {
  const { verifyNoSignupInLocalStorage, promo, excess, setExcess } =
    useAppContext();

  const { boilerDetails } = StoreService.load();

  const { quotes } = useQuote({
    promo,
    excess,
    boilerAge: parseInt(boilerDetails.boilerAge),
  });

  const absQuote = quotes.find((quote) => quote.coverType === CoverType.ABS);

  useEffect(() => {
    verifyNoSignupInLocalStorage();
    setExcess(0);
    StoreService.save({
      selectedCoverType: absQuote?.coverType,
      excess: 0,
      isHomeRecover: false,
      isBeyond: false,
      isAbs: true,
      isAgent: false,
    });
  }, [promo, verifyNoSignupInLocalStorage]);

  useEffect(() => {
    PromoService.handlePromoFromUrl();
  }, []);

  return <AbsPage {...props} quote={absQuote} />;
};

export default Abs;
